@font-face {
  font-family: Mutualidad;
  src        : url(fonts/Mutualidad-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: Mutualidad;
  src        : url(fonts/Mutualidad-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: Rufina;
  src        : url(fonts/Rufina-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: Rufina;
  src        : url(fonts/Rufina-Bold.ttf) format('truetype');
  font-weight: 700;
}